
























































































import {
  computed,
  defineComponent,
  ref,
  toRefs,
  watch
} from "@vue/composition-api";
import PdfViewer from "@/components/view/Pdf.vue";
import LaravelError from "@/components/view/LaravelError.vue";
import { useMaklerdokumente } from "@/lib/maklerdokumente";
import { useUser } from "@/lib/vimbApi/useUser";
import { Routes } from "@/router/types";
import { isEmbedded } from "@/services/embedded";
import { DocumentType } from "@/types/maklerdokumente/DocumentTypes";

const getTypeName = (type: string) => {
  if (type === DocumentType.MAKLERVOLLMACHT) return "Maklervollmacht";
  if (type === DocumentType.ERSTINFORMATION) return "Erstinformation";
  if (type === DocumentType.EINWILLIGUNGSERKLAERUNG)
    return "Einwilligungserklärung";
  return undefined;
};

const date = () => new Date().toISOString().replace(/[^\d]/g, "");

export default defineComponent({
  components: { PdfViewer, LaravelError },
  props: { type: { type: String, default: "" } },
  setup(props) {
    const { type } = toRefs(props);
    const { loadMe, vermittlerNumber } = useUser();
    const {
      loadDocument,
      validateDocument,
      getDocument,
      getValidation
    } = useMaklerdokumente();

    const validation = computed(() => getValidation(type.value));
    const document = computed(() => getDocument(type.value));
    const embedded = computed(() => isEmbedded());
    const typeName = computed(() => getTypeName(type.value));
    const downloadName = computed(() => `${type.value}-preview-${date()}`);

    loadMe();

    watch(vermittlerNumber, v => v && validateDocument(v), { immediate: true });
    watch([type], ([type]) => type && loadDocument(type), { immediate: true });

    return {
      Routes,
      embedded,
      validation,
      document,
      typeName,
      downloadName,
      pdf: ref()
    };
  }
});
